import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import { format } from '../../helpers/formatter';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const StyledModal = styled(Modal)`
  width: 500px;
`;

const CloseButton = styled(Button)`
  border: none;
  padding: 0;
`;

const BottomRow = styled(Row)`
  border-top: 1px #d3d3d3 solid;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  width: 130px;
  font-size: 14px;
  margin-top: 15px;
`;

const CanceledRecordsModal = ({
  isOpen,
  toggleModal,
  canceledRecordsCount,
  handleProceed,
}) => {
  return (
    <StyledModal toggle={toggleModal} isOpen={isOpen}>
      <ModalBody>
        <HeaderRow>
          <Col xs={11}>
            <h4>Canceled records included</h4>
          </Col>
          <Col xs={1}>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Col>
        </HeaderRow>
        <Row>
          <Col>
            This operation will affect {format(canceledRecordsCount)} canceled
            records. Do you want to proceed?
          </Col>
        </Row>
        <BottomRow>
          <Col sm={4}></Col>
          <Col sm={4}>
            <StyledButton onClick={toggleModal} color="secondary">
              No, cancel
            </StyledButton>
          </Col>
          <Col sm={4}>
            <StyledButton onClick={handleProceed} color="primary">
              Yes, proceed
            </StyledButton>
          </Col>
        </BottomRow>
      </ModalBody>
    </StyledModal>
  );
};

CanceledRecordsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  canceledRecordsCount: PropTypes.number.isRequired,
  handleProceed: PropTypes.func.isRequired,
};

export default CanceledRecordsModal;
