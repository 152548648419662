import urls from './urls';
import client from '../utils/api';

const getBillings = (query = {}) =>
  client.get(urls.billings.getBillings(), query);
const editBillings = ({ id, params }) =>
  client.put(urls.billings.editBillings(id), params);
const getBilling = ({ id }) => client.get(urls.billings.getBilling(id));
const sentBillings = ({ id, params }) =>
  client.put(urls.billings.editBillings(id), params);
const downloadBillingsByVBELP = ({ id }) =>
  client.gateway({
    method: 'GET',
    url: urls.billings.downloadBillingsByVBELP(id),
    responseType: 'blob',
  });
const downloadBillingsBySalesOrder = ({ id }) =>
  client.gateway({
    method: 'GET',
    url: urls.billings.downloadBillingsBySalesOrder(id),
    responseType: 'blob',
  });
const deleteBilling = ({ id }) =>
  client.del(urls.billings.deleteBilling(id), { id });

export default {
  getBillings,
  editBillings,
  deleteBilling,
  sentBillings,
  getBilling,
  downloadBillingsByVBELP,
  downloadBillingsBySalesOrder,
};
