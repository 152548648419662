import { makeReducer } from '../common';
import {
  GET_RECORDS,
  FETCH_FILTERS,
  TOGGLE_ASSIGN_RECORDS_MODAL,
  ASSIGN_RECORDS_TO_PROJECT,
  TOGGLE_ASSIGN_ALL_MODAL,
  ASSIGN_ALL_TO_PROJECT,
  EDIT_RECORD,
  CLEAR_EDIT_RECORD_ERROR,
  EXPORT_CSV,
  TOGGLE_BILLED_RECORDS_MODAL,
  PROCESS_RECORD,
  MOVE_RECORDS_TO_BILLED,
  BULK_ACTION,
  SET_ROW_DATA_ARRAY,
  CHECK_CANCELED_STATUS,
  CLEAR_CANCELED_RECORDS_COUNT,
} from './actionTypes';

export default makeReducer(
  {
    [GET_RECORDS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_RECORDS.SUCCESS]: (state, { payload }) => ({
      ...state,
      records: payload,
      isLoading: false,
    }),
    [GET_RECORDS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [FETCH_FILTERS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_FILTERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      filters: {
        availableFilters: payload.availableFilters || [],
        filters: payload.filters || [],
      },
      isLoading: false,
    }),
    [FETCH_FILTERS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [TOGGLE_ASSIGN_RECORDS_MODAL.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_ASSIGN_RECORDS_MODAL.SUCCESS]: (state) => ({
      ...state,
      isLoading: false,
      isOpen: false,
    }),
    [TOGGLE_ASSIGN_RECORDS_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [TOGGLE_BILLED_RECORDS_MODAL.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_BILLED_RECORDS_MODAL.SUCCESS]: (state) => ({
      ...state,
      isOpen: false,
      isLoading: false,
    }),
    [TOGGLE_BILLED_RECORDS_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [ASSIGN_RECORDS_TO_PROJECT.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [ASSIGN_RECORDS_TO_PROJECT.SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => ({
      ...state,
      records: data,
      error: null,
      isLoading: false,
      affectedRows: data.numberOfAffectedRows,
    }),
    [ASSIGN_RECORDS_TO_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [PROCESS_RECORD.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [PROCESS_RECORD.SUCCESS]: (state) => ({
      ...state,
      error: null,
      isLoading: false,
    }),
    [PROCESS_RECORD.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [MOVE_RECORDS_TO_BILLED.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [MOVE_RECORDS_TO_BILLED.SUCCESS]: (state) => ({
      ...state,
      error: null,
      isLoading: false,
    }),
    [MOVE_RECORDS_TO_BILLED.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [TOGGLE_ASSIGN_ALL_MODAL.RUN]: (state) => ({
      ...state,
      isOpenAssignAll: !state.isOpenAssignAll,
      error: null,
    }),
    [TOGGLE_ASSIGN_ALL_MODAL.SUCCESS]: (state) => ({
      ...state,
      isOpenAssignAll: false,
      isLoading: false,
    }),
    [TOGGLE_ASSIGN_ALL_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpenAssignAll: false,
      error,
    }),
    [ASSIGN_ALL_TO_PROJECT.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [ASSIGN_ALL_TO_PROJECT.SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      return {
        ...state,
        records: data,
        error: null,
        isLoading: false,
      };
    },
    [ASSIGN_ALL_TO_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [EDIT_RECORD.RUN]: (state) => ({
      ...state,
    }),
    [EDIT_RECORD.SUCCESS]: (state, { payload: { indulgence } }) => ({
      ...state,
      records: {
        ...state.records,
        results: state.records.results.map((row) =>
          row.id === indulgence.id ? { ...row, ...indulgence } : row,
        ),
      },
    }),
    [EDIT_RECORD.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [CLEAR_EDIT_RECORD_ERROR.RUN]: (state) => ({
      ...state,
      error: null,
    }),
    [EXPORT_CSV.RUN]: (state) => ({
      ...state,
    }),
    [EXPORT_CSV.SUCCESS]: (state) => ({
      ...state,
    }),
    [EXPORT_CSV.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [BULK_ACTION.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [BULK_ACTION.SUCCESS]: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      return {
        ...state,
        records: data,
        error: null,
        isLoading: false,
      };
    },
    [BULK_ACTION.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [SET_ROW_DATA_ARRAY.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [SET_ROW_DATA_ARRAY.SUCCESS]: (state, payload) => ({
      ...state,
      isLoading: false,
      rowDataArray: payload.payload,
    }),
    [SET_ROW_DATA_ARRAY.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [CHECK_CANCELED_STATUS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [CHECK_CANCELED_STATUS.SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      canceledRecordsCount: payload.data.data,
    }),
    [CHECK_CANCELED_STATUS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [CLEAR_CANCELED_RECORDS_COUNT.RUN]: (state) => ({
      ...state,
      canceledRecordsCount: null,
    }),
  },
  {
    records: {
      results: [],
      filteredCount: null,
      count: null,
      withStatusNew: null,
      totalOffsetOfNew: null,
      isReady: false,
      hasUnfinishedTasks: false,
      params: {
        sortableColumns: [],
      },
    },
    isOpenAssignAll: false,
    isOpen: false,
    isLoading: false,
    error: null,
    affectedRows: null,
    filters: {
      availableFilters: [],
      filters: [],
    },
    canceledRecordsCount: null,
  },
);
