import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import MetisMenu from '@metismenu/react';
import SidebarLink from './Link';
import { withUser } from '../../../context/UserContext';
import { isAdmin } from '../../../utils/cookies';
import { useUnknownSvcpns } from '../../../hooks';
import logo from '../../../assets/logo.png';

const Sidebar = ({ isCollapsed }) => {
  if (typeof document !== 'undefined') {
    if (isCollapsed) {
      document.body.classList.add('vertical-collpsed');
    } else {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  useEffect(() => {
    const action = isCollapsed ? 'add' : 'remove';
    document.body.classList[action]('vertical-collpsed');
  }, [isCollapsed]);

  const { unknownSvcpns } = useUnknownSvcpns();

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box px-0">
        <NavLink to="/home" exact className="logo">
          <img src={logo} alt="logo" width={24} />
          <span
            className={cx({
              'h3 text-white align-middle ps-3 pe-4': !isCollapsed,
              'd-none': isCollapsed,
            })}
          >
            Lenovo OMS
          </span>
        </NavLink>
      </div>
      <div id="sidebar-menu">
        <React.Fragment>
          <MetisMenu>
            <SidebarLink
              to="/home"
              exact
              icon="bx-home-alt"
              isCollapsed={isCollapsed}
            >
              Home
            </SidebarLink>
            <SidebarLink
              to="/billings"
              exact
              icon="bx-wallet"
              isCollapsed={isCollapsed}
            >
              Billing Summary
            </SidebarLink>
            <SidebarLink
              to="/svcpns"
              exact
              icon="bx-cloud"
              isCollapsed={isCollapsed}
            >
              SVCPNs{' '}
              {unknownSvcpns && unknownSvcpns.results.length !== 0 ? (
                <span style={{ fontSize: '8px' }}>&#128308;</span>
              ) : (
                ''
              )}
            </SidebarLink>
            <SidebarLink
              to="/projects"
              exact
              icon="bx-briefcase-alt-2"
              isCollapsed={isCollapsed}
            >
              Projects
            </SidebarLink>
            <SidebarLink
              to="/retirements"
              exact
              icon="bx-note"
              isCollapsed={isCollapsed}
            >
              Retirements
            </SidebarLink>
            {isAdmin() && (
              <SidebarLink
                to="/event-log"
                icon="bx-history"
                isCollapsed={isCollapsed}
              >
                Event Log
              </SidebarLink>
            )}
            {isAdmin() && (
              <SidebarLink
                to="/users"
                exact
                icon="bx-user"
                isCollapsed={isCollapsed}
              >
                Users
              </SidebarLink>
            )}
            {isAdmin() && (
              <SidebarLink
                to="/settings"
                icon="bx-cog"
                isCollapsed={isCollapsed}
              >
                Settings
              </SidebarLink>
            )}
          </MetisMenu>
        </React.Fragment>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(Sidebar);
