import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { exportCsv } from '../../store/records';
import { withUser } from '../../context/UserContext';
import exportIcon from '../../assets/images/export.svg';
import { format } from '../../helpers/formatter';

const Export = ({ params, count }) => {
  const dispatch = useDispatch();
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const toggleWarning = () => setIsWarningOpen(!isWarningOpen);

  const confirmExport = () => {
    toggleWarning();
    dispatch(exportCsv(params));
  };

  return (
    <>
      <Button
        className="btn-secondary mr-2"
        onClick={toggleWarning}
        disabled={count === null || count === 0}
      >
        <img src={exportIcon} alt="export" />
        <span>Export CSV</span>
      </Button>

      <Modal isOpen={isWarningOpen} toggle={toggleWarning}>
        <ModalHeader toggle={toggleWarning}>Export CSV</ModalHeader>
        <ModalBody>
          You are about to export {format(count)} record(s). Are you sure you
          want to export the record(s)?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleWarning}>
            No, cancel
          </Button>
          <Button color="primary" onClick={confirmExport}>
            Yes, export
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

Export.propTypes = {
  params: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
};

export default React.memo(withUser(Export));
