import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';

const makeCheckboxFilter = ({ column, placeholder }) => {
  const CheckboxFilter = ({ filters, makeSetFilter }) => {
    const value = useMemo(() => filters[column]?.value ?? false, [filters]);
    const setValue = useCallback(
      () => makeSetFilter(column, false, true)(!value),
      [makeSetFilter, value],
    );

    return (
      <FormGroup check inline>
        <Input
          type="checkbox"
          id={`${column}-checkbox-id`}
          checked={value}
          onChange={setValue}
        />
        <Label check htmlFor={`${column}-checkbox-id`} className="fw-normal">
          {placeholder}
        </Label>
      </FormGroup>
    );
  };

  CheckboxFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return CheckboxFilter;
};

export default makeCheckboxFilter;
