import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Button } from 'reactstrap';
import ReactTagInput from '@pathofdev/react-tag-input';
import styled from 'styled-components';
import '@pathofdev/react-tag-input/build/index.css';
import cx from 'classnames';
import cross from '../../../assets/images/cross.svg';

const StyledInput = styled(InputGroup)`
  z-index: 0;
`;

const ClearButton = styled.span`
  position: absolute;
  top: 8px;
  right: 90px;
  cursor: pointer;
`;

const makeMultiValueFilter = ({ column, placeholder }) => {
  const MultiValueFilter = ({ filters, makeSetFilter }) => {
    const [tags, setTags] = useState([]);
    const inputRef = useRef(null);
    const [inputKey, setInputKey] = useState(0); // To force re-render ReactTagInput on search

    useEffect(() => {
      const newTags = filters[column]?.value || [];
      setTags(newTags);
    }, [filters]);

    const setValue = useCallback(makeSetFilter(column), [makeSetFilter]);
    const search = useCallback(makeSetFilter(column, true), [makeSetFilter]);

    const handleOnChange = (newTags) => {
      const uppercasedTags = newTags.map((tag) => tag.toUpperCase());
      const splittedTags = uppercasedTags
        .map((tag) => tag.split(/[\s,.]+/))
        .flat()
        .filter((tag) => tag !== '');
      setTags(splittedTags);
      setValue(splittedTags);
    };

    const handleSearch = () => {
      const currentInput = inputRef.current.inputRef.current.value.trim();

      let splittedTags = tags;

      if (currentInput) {
        const newTags = [...tags, currentInput.toUpperCase()];
        splittedTags = newTags.map((tag) => tag.split(/[\s,.]+/)).flat();

        setTags(splittedTags);
        setValue(splittedTags);

        setInputKey(inputKey + 1);
      }

      search(splittedTags);
    };

    return (
      <>
        <StyledInput className={cx({ 'filled-input': tags.length > 0 })}>
          <div className="input-group-text">
            <i className="bx bx-search-alt search-icon" />
          </div>
          <ReactTagInput
            ref={inputRef}
            key={inputKey}
            tags={tags}
            editable={true}
            placeholder={placeholder}
            removeOnBackspace={true}
            onChange={handleOnChange}
          />
          {tags.length > 0 && (
            <ClearButton
              onClick={() => {
                setTags([]);
                setValue([]);
              }}
            >
              <img src={cross} alt="cross" />
            </ClearButton>
          )}
          <Button color="primary" onClick={handleSearch}>
            Search
          </Button>
        </StyledInput>
      </>
    );
  };

  MultiValueFilter.propTypes = {
    filters: PropTypes.object.isRequired,
    makeSetFilter: PropTypes.func.isRequired,
  };

  return MultiValueFilter;
};

export default makeMultiValueFilter;
