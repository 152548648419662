import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

const UserTable = ({
  isLoading,
  data,
  columns,
  page,
  sizePerPage,
  totalSize,
  onPageChange,
}) => (
  <PaginationProvider
    pagination={paginationFactory({
      page,
      sizePerPage,
      totalSize,
      custom: true,
    })}
  >
    {({ paginationProps, paginationTableProps }) => (
      <>
        <Row>
          <Col xl="12">
            <div className="mt-4">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={data}
                loading={isLoading}
                responsive
                bordered={false}
                striped={false}
                classes="table align-middle table-nowrap"
                headerWrapperClasses="table-light"
                remote={{ pagination: true }}
                {...paginationTableProps}
              />
            </div>
          </Col>
        </Row>

        <Row className="align-items-md-center mt-3">
          <Col className="inner-custom-pagination d-flex">
            <div className="text-md-right ms-auto">
              <PaginationListStandalone
                {...paginationProps}
                onPageChange={onPageChange}
              />
            </div>
          </Col>
        </Row>
      </>
    )}
  </PaginationProvider>
);

UserTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default UserTable;
