import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { NotFound } from '../errors';
import PageTitle from '../../components/Common/PageTitle';
import { withUser } from '../../context/UserContext';
import { useEmailRecipients } from '../../hooks';
import EmailRecipientsCard from './EmailRecipientsCard';

const Settings = ({ user }) => {
  if (!user || user.role !== 'ADMIN') {
    return <NotFound />;
  }

  const { data, isLoading } = useEmailRecipients();

  const processingRecipients = data.filter(
    (recipient) => recipient.type === 'PROCESSING',
  );

  const failedTransmissionRecipients = data.filter(
    (recipient) => recipient.type === 'FAILED_TRANSMISSION',
  );

  const transmissionIssuesRecipients = data.filter(
    (recipient) => recipient.type === 'TRANSMISSION_ISSUES',
  );

  return (
    <div className="page-content">
      <PageTitle title="Settings" />
      <Container fluid>
        <Col xs={10}>
          <h3>Settings</h3>
        </Col>
        <Row>
          <Col xl={5}>
            <Link to="/settings/failed-transmission-recipients">
              <EmailRecipientsCard
                isLoading={isLoading}
                recipients={failedTransmissionRecipients}
                type="FAILED_TRANSMISSION"
              />
            </Link>
          </Col>
          <Col xl={5}>
            <Link to="/settings/processing-recipients">
              <EmailRecipientsCard
                isLoading={isLoading}
                recipients={processingRecipients}
                type="PROCESSING"
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xl={5}>
            <Link to="/settings/transmission-issues-recipients">
              <EmailRecipientsCard
                isLoading={isLoading}
                recipients={transmissionIssuesRecipients}
                type="TRANSMISSION_ISSUES"
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(Settings);
