import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import IconButton from '../../components/Tables/IconButton';
import CloseIcon from '../../components/Common/CloseIcon';
import { toggleAssignRecordsModal } from '../../store/records';
import { records } from '../../api';
import { useToggle } from '../../hooks';
import AssignToProjectWrapper from './AssignToProjectWrapper';
import CanceledRecordsModal from '../shared/CancelledRecordsModal';

const BottomRow = styled(Row)`
  margin-top: 15px;
`;

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const ModalButton = styled(Button)`
  width: 150px;
  font-size: 16px;
`;

const OkButton = styled(Button)`
  width: 120px;
  font-size: 16px;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const AssignToProject = ({ isOpenAssignModal, selectedRecords, params }) => {
  const dispatch = useDispatch();
  const [isAll, isAllModal] = useToggle();
  const [isOne, isOneModal] = useToggle();
  const [isCanceledModal, setIsCanceledModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const canceledRecords = selectedRecords.filter((record) => record.isCanceled);

  const toggleModal = async () => {
    return dispatch(toggleAssignRecordsModal());
  };

  const checkStatus = async () => {
    const { data } = await records.checkStatus({
      indulgenceIds: selectedRecords.map((record) => record.id),
      status: 'NEW',
    });

    if (data.all) {
      return isAllModal();
    }
    if (data.hasOne) {
      const ids = data.records.map((record) => record.id);
      setRowData(selectedRecords.filter((val) => !ids.includes(val.id)));
      return isOneModal();
    }
    setRowData(selectedRecords);
    return toggleModal();
  };

  const handleProceedAfterCancel = () => {
    setIsCanceledModal(false);
    checkStatus();
  };

  return (
    <>
      <IconButton
        color="success"
        icon="bx-briefcase-alt-2"
        text="Assign to project"
        onClick={() => {
          if (canceledRecords.length > 0) {
            setIsCanceledModal(true);
          } else {
            checkStatus();
          }
        }}
      />

      <CanceledRecordsModal
        isOpen={isCanceledModal}
        toggleModal={() => setIsCanceledModal(false)}
        canceledRecordsCount={canceledRecords.length}
        handleProceed={handleProceedAfterCancel}
      />

      <StyledModal toggle={isAllModal} isOpen={isAll}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Assign to project</h4>
            </Col>
            <CloseButton color="link" onClick={checkStatus}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <Row>
            <Col>
              The records you selected are not “New” anymore, so they cannot be
              assigned to the project.
            </Col>
          </Row>
          <BottomRow>
            <Col md={4}></Col>
            <Col className="justify-content-center">
              <OkButton onClick={isAllModal} color="secondary">
                OK
              </OkButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>

      <StyledModal toggle={isOneModal} isOpen={isOne}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Assign to project</h4>
            </Col>
            <CloseButton color="link" onClick={checkStatus}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <Row>
            <Col>
              Some of the records have been skipped since they are not in the
              “New” status anymore. Proceed with assigning the rest of the
              records to the project?
            </Col>
          </Row>
          <BottomRow>
            <Col>
              <ModalButton onClick={isOneModal} color="secondary">
                No, cancel
              </ModalButton>
            </Col>
            <Col className="text-end">
              <ModalButton
                onClick={async () => {
                  await checkStatus();
                  return toggleModal();
                }}
                color="primary"
              >
                Yes, proceed
              </ModalButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>

      <StyledModal toggle={toggleModal} isOpen={isOpenAssignModal}>
        <AssignToProjectWrapper
          selectedRecords={selectedRecords}
          params={params}
          rowData={rowData}
        />
      </StyledModal>
    </>
  );
};

export default AssignToProject;

AssignToProject.propTypes = {
  params: PropTypes.object.isRequired,
  isOpenAssignModal: PropTypes.bool.isRequired,
  selectedRecords: PropTypes.array.isRequired,
};
