/* eslint-disable */
import React from 'react';
import DatePicker from 'react-datepicker';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { addDays } from 'date-fns';
import TextError from './TextError';

import 'react-datepicker/dist/react-datepicker.css';

const CustomPicker = styled(DatePicker)`
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
`;

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      <CustomPicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        onChangeRaw={() => {
          setFieldTouched(field.name, true, true);
        }}
        dateFormat="yyyy.MM.dd"
        autoComplete="off"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Select Today"
      />
      <ErrorMessage component={TextError} name={field.name} />
    </>
  );
};
