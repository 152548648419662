import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import {
  createUser,
  deleteUser,
  updateUser,
  updateUserPassword,
  getUsers,
  toggleUserModal,
  updatePassword,
} from '../store/users';
import { user } from '../utils/cookies';

export const useUsers = (
  initialParams = {
    page: 1,
    pageSize: 10,
  },
) => {
  const [paginationParams, updatePaginationParams] = useQuery(initialParams);
  // eslint-disable-next-line prettier/prettier
  const data = useSelector((state) => state.users.users.results);
  const totalSize = useSelector((state) => state.users.users.count);
  const userData = useSelector((state) => state.users.currentUser);
  const error = useSelector((state) => state.users.error);
  const isLoading = useSelector((state) => state.users.isLoading);
  const isModalOpen = useSelector((state) => state.users.isOpen);
  const modalType = useSelector((state) => state.users.modalType);
  const message = useSelector((state) => state.users.message);

  const dispatch = useDispatch();
  const createNewUser = (params) => dispatch(createUser(params));
  const editUser = (params) => dispatch(updateUser(params));
  const editUserPassword = (params) => dispatch(updateUserPassword(params));
  const editPassword = (params) => dispatch(updatePassword(params));
  const deleteUserById = (params) => dispatch(deleteUser(params));
  const toggleModal = (type) => dispatch(toggleUserModal({ type }));

  const fetchUsers = () => {
    dispatch(getUsers({ params: paginationParams }));
  };

  const currentUser = useMemo(() => user.value(), [userData]);

  return {
    paginationParams,
    updatePaginationParams,
    data,
    totalSize,
    currentUser,
    error,
    isLoading,
    isModalOpen,
    modalType,
    createNewUser,
    deleteUserById,
    toggleModal,
    editUser,
    editUserPassword,
    editPassword,
    message,
    fetchUsers,
  };
};
