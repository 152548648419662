import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import TablePage from '../../components/Tables/Page';
import { useAdvancedSearch, useFetchFilters } from '../../hooks';
import RecordsTable from '../../components/Tables/RecordsTable';
import RecordsFilter from '../../components/Tables/RecordsFilter';
import SortCaret from '../../components/Tables/SortCaret';
import RecordActionButtons from './RecordActionButtons';

const statusFormatter = (cell, row) => {
  let statusParams;
  switch (row.status) {
    case 'NEW':
      statusParams = {
        styles: { color: '#50A5F1', background: '#D3E8FB' },
        text: 'New',
      };
      break;
    case 'IN_PROGRESS':
      statusParams = {
        styles: { color: '#556EE6', background: '#D4DBF9' },
        text: 'In progress',
      };
      break;
    case 'READY':
      statusParams = {
        styles: { color: '#F1B44C', background: '#FBECD2' },
        text: 'Ready',
      };
      break;
    case 'DONE':
      statusParams = {
        styles: { color: '#34C38F', background: '#CCF0E3' },
        text: 'Done',
      };
      break;
    case 'INVALID':
      statusParams = {
        styles: { color: '#F46A6A', background: '#FCDADA' },
        text: 'Invalid',
      };
      break;
    case 'BILLED':
      statusParams = {
        styles: { color: '#343A40', background: '#CCCECF' },
        text: 'Billed',
      };
      break;
    case 'DUPLICATE':
      statusParams = {
        styles: { color: '#74788D', background: '#DCDDE2' },
        text: 'Duplicate',
      };
      break;
    default:
      statusParams = {
        styles: { color: '#F46A6A', background: '#FCDADA' },
        text: 'Unknown',
      };
  }

  return (
    <span
      style={{
        ...{ borderRadius: '10px', textAlign: 'center', padding: '0px 10px' },
        ...statusParams.styles,
      }}
    >
      {statusParams.text}
    </span>
  );
};

const cancellationFormatter = (cell) => {
  const statusParams = {
    styles: cell ? { color: '#d4462a' } : {},
    text: cell ? 'Canceled' : 'Valid',
  };

  return (
    <span
      style={{
        ...statusParams.styles,
      }}
    >
      {statusParams.text}
    </span>
  );
};

const numberFormatter = (cell) => {
  if (cell === null || cell === '') {
    return <span>-</span>;
  }

  return cell;
};

const dateFormatter = (cell) => {
  if (!cell) {
    return '';
  }

  return moment.utc(cell).format('YYYY.MM.DD');
};

const initialColumns = [
  {
    dataField: 'uid',
    text: 'UID',
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    active: true,
  },
  {
    dataField: 'serialNumber',
    text: 'Serial No',
    active: true,
  },
  {
    dataField: 'orderedAt',
    text: 'Order Date',
    formatter: dateFormatter,
    active: false,
  },
  {
    dataField: 'product',
    text: 'Product',
    active: true,
  },
  {
    dataField: 'salesOrderItemShipAt',
    text: 'Machine Ship Date',
    formatter: dateFormatter,
    active: true,
  },
  {
    dataField: 'regionName',
    text: 'Geography',
    active: true,
  },
  {
    dataField: 'salesOrderItemShippingCountry',
    text: 'Ship Country',
    active: true,
  },
  {
    dataField: 'svcpn',
    text: 'SVCPN',
    active: true,
  },
  {
    dataField: 'svcpnDescription',
    text: 'SVCPN Description',
    active: true,
  },
  {
    dataField: 'sdfCode',
    text: 'SDF Code',
    active: true,
  },
  {
    dataField: 'offset',
    text: 'Offset',
    active: true,
  },
  {
    dataField: 'salesOrderNumber',
    text: 'Sales Order No',
    active: true,
  },
  {
    dataField: 'customerShipName',
    text: 'Customer Ship Name',
    active: true,
  },
  {
    dataField: 'customerSoldName',
    text: 'Customer Sold Name',
    active: true,
  },
  {
    dataField: 'isCanceled',
    text: 'Cancellation',
    active: true,
    formatter: cancellationFormatter,
  },
  {
    dataField: 'mtmNumber',
    text: 'MTM Number',
    active: false,
    formatter: numberFormatter,
  },
  {
    dataField: 'vbelpNumber',
    text: 'VBELP Number',
    active: false,
    formatter: numberFormatter,
  },
];

const persistentColumns = [
  {
    dataField: 'projectCode',
    text: 'Project No',
  },
  {
    dataField: 'reference',
    text: 'Reference No',
  },
  {
    dataField: 'offsetedAt',
    text: 'Offset Date',
    formatter: dateFormatter,
  },
  {
    dataField: 'certificateId',
    text: 'Certificate ID',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: statusFormatter,
  },
];

const AdvancedSearch = () => {
  const [switchableColumns, setSwitchableColumns] = useState(initialColumns);
  const [rowData, setRowData] = useState([]);

  const { data, sortableColumns, filteredCount, count, isLoading } =
    useAdvancedSearch();

  const {
    loadRecords,
    makeSetFilter,
    availableFilters,
    filters,
    params,
    updateParams,
    setPageChanged,
    setSortChanged,
  } = useFetchFilters();

  const clearFilter = () => window.location.assign('/home');

  const { isOpen } = useSelector((state) => ({
    isOpen: state.records.isOpen,
  }));

  useEffect(() => {
    setRowData([]);
    const settings = JSON.parse(localStorage.getItem('settings'));

    if (settings) {
      const configuredColumns = settings.map((column) => {
        switch (column.dataField) {
          case 'orderedAt':
          case 'salesOrderItemShipAt':
            return { ...column, formatter: dateFormatter };
          case 'mtmNumber':
          case 'vbelpNumber':
            return { ...column, formatter: numberFormatter };
          case 'isCanceled':
            return { ...column, formatter: cancellationFormatter };
          default:
            return column;
        }
      });
      setSwitchableColumns(configuredColumns);
    }
  }, [data]);

  // That useEffect is to make sure that after new fields added we reset the settings in localStorage
  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings?.length !== initialColumns.length) {
      localStorage.setItem('settings', JSON.stringify(initialColumns));
    }
  }, [data]);

  const nonSelectableRows =
    data &&
    data.flatMap((row) =>
      row.status === 'IN_PROGRESS' ||
      row.status === 'BILLED' ||
      row.status === 'INVALID' ||
      row.status === 'DUPLICATE'
        ? row.id
        : [],
    );

  const selectRow = {
    mode: 'checkbox',
    selected: rowData.map((row) => row.id),
    style: { '--bs-table-bg': 'initial', backgroundColor: '#556EE654' },
    nonSelectable: nonSelectableRows,
    selectionHeaderRenderer: ({ indeterminate, checked }) => (
      <Input
        type="checkbox"
        readOnly
        checked={checked}
        innerRef={(input) => {
          // eslint-disable-next-line no-param-reassign
          if (input) input.indeterminate = indeterminate;
        }}
      />
    ),
    selectionRenderer: ({ checked, disabled }) => (
      <Input type="checkbox" readOnly checked={checked} disabled={disabled} />
    ),
    onSelect: (row, isSelect) => {
      if (isSelect === true) {
        setRowData((oldData) => [...oldData, row]);
      } else {
        setRowData((dataToFilter) =>
          dataToFilter.filter((x) => x.id !== row.id),
        );
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect === true) {
        setRowData((oldData) => [...oldData, ...rows]);
      } else {
        const idsToRemove = rows.map((row) => row.id);
        setRowData((oldData) =>
          oldData.filter((x) => !idsToRemove.includes(x.id)),
        );
      }
    },
  };

  const columnsWithSorting = useMemo(() => {
    const enabledColumns = switchableColumns.filter((item) => item.active);
    return [...enabledColumns, ...persistentColumns].map((column) => ({
      ...column,
      sort: sortableColumns.includes(column.dataField),
      sortCaret: (order) => <SortCaret order={order} />,
    }));
  }, [switchableColumns, sortableColumns.join('')]);

  const onTableChange = async (type, newState) => {
    if (
      type === 'sort' &&
      (params.sortingColumn !== newState.sortField ||
        params.sortingDirection !== newState.sortOrder)
    ) {
      setSortChanged(true);
      updateParams({
        ...params,
        sortingColumn: newState.sortField,
        sortingDirection: newState.sortOrder,
      });
    }
    if (type === 'pagination') {
      setPageChanged(true);
      updateParams({
        ...params,
        page: newState.page,
        pageSize: newState.sizePerPage,
      });
    }
  };

  return (
    <TablePage
      title="Records"
      subTitle="List of records"
      filteredCount={filteredCount}
      count={count}
      params={params}
    >
      <>
        {rowData && rowData.length > 0 ? (
          <RecordActionButtons
            isOpen={isOpen}
            selectedRecords={rowData}
            params={params}
          />
        ) : null}
        <RecordsFilter
          applyFilter={loadRecords}
          makeSetFilter={makeSetFilter}
          availableFilters={availableFilters}
          filters={filters}
          clearFilter={clearFilter}
          switchableColumns={switchableColumns}
          setSwitchableColumns={setSwitchableColumns}
        />
        <RecordsTable
          data={data}
          page={params.page}
          isLoading={isLoading}
          sizePerPage={params.pageSize}
          sort={{
            dataField: params.sortingColumn,
            order: params.sortingDirection,
          }}
          totalSize={filteredCount}
          columns={columnsWithSorting}
          onTableChange={onTableChange}
          selectRow={selectRow}
        />
      </>
    </TablePage>
  );
};

export default AdvancedSearch;
