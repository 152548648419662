import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import docInfoIcon from '../../assets/images/doc_info.svg';

const HeaderRow = styled(Row)`
  border-bottom: 1px #d3d3d3 solid;
  margin-bottom: 15px;
`;

const CloseButton = styled(Button)`
  border: none;
  padding: 0;
`;

const BottomRow = styled(Row)`
  border-top: 1px #d3d3d3 solid;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  width: 130px;
  font-size: 16px;
  border-top: 1px #d3d3d3 solid;
  margin-top: 15px;
  font-size: 14px;
`;

const BodySection = styled.div`
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  max-height: 70vh;
  overflow-y: auto;
  word-wrap: break-word;
`;

const TransmissionLogView = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [copyStatus, setCopyStatus] = useState('Copy');

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setCopyStatus('Copy');
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCopyStatus('Copy');
    }
  };

  const handleCopy = () => {
    const contentToCopy =
      activeTab === '1' ? row.requestBody : row.responseBody;

    navigator.clipboard.writeText(contentToCopy).then(() => {
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy'), 2000);
    });
  };

  return (
    <>
      <Button className="btn-secondary" size="sm" onClick={toggleModal}>
        <img src={docInfoIcon} alt="doc-info" />
        <span style={{ fontSize: '14px' }}>Details</span>
      </Button>

      <Modal toggle={toggleModal} isOpen={isOpen}>
        <ModalBody>
          <HeaderRow>
            <Col xs={11}>
              <h4>Transmission Log Details</h4>
            </Col>
            <Col xs={1}>
              <CloseButton color="link" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Col>
          </HeaderRow>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => toggleTab('1')}
              >
                Request Body
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => toggleTab('2')}
              >
                Response Body
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <BodySection>
                <span>{row.requestBody}</span>
              </BodySection>
            </TabPane>
            <TabPane tabId="2">
              <BodySection>
                <span>{row.responseBody}</span>
              </BodySection>
            </TabPane>
          </TabContent>

          <BottomRow>
            <Col sm={4} />
            <Col sm={4} />
            <Col sm={4}>
              <StyledButton color="primary" onClick={handleCopy}>
                {copyStatus}
              </StyledButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </Modal>
    </>
  );
};

TransmissionLogView.propTypes = {
  row: PropTypes.shape({
    requestBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    responseBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default TransmissionLogView;
