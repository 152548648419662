import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardBody, CardTitle, CardText, Tooltip } from 'reactstrap';
import outEnvelope from '../../assets/images/out-envelope.svg';
import inEnvelope from '../../assets/images/in-envelope.svg';
import alert from '../../assets/images/alert.svg';

const MAX_RECIPIENTS_DISPLAY = 2;

const StyledCard = styled(Card)`
  border: 1px solid #c2c3c4;
  background-color: white;
  &:hover {
    background-color: #f8f9fa;
  }
`;

const getTitle = (type) => {
  switch (type) {
    case 'PROCESSING':
      return 'Outgoing Transmission Emails';
    case 'FAILED_TRANSMISSION':
      return 'Incoming Transmission Emails';
    case 'TRANSMISSION_ISSUES':
      return 'Transmission Alert Emails';
    default:
      return '';
  }
};

const getDescription = (type) => {
  switch (type) {
    case 'PROCESSING':
      return 'Specify email addresses to receive notifications on outgoing transmissions';
    case 'FAILED_TRANSMISSION':
      return 'Specify email addresses to receive notifications on failures within incoming transmissions';
    case 'TRANSMISSION_ISSUES':
      return 'Specify email addresses to receive notifications on incoming transmission issues';
    default:
      return '';
  }
};

const getEnvelopeImage = (type) => {
  switch (type) {
    case 'PROCESSING':
      return outEnvelope;
    case 'FAILED_TRANSMISSION':
      return inEnvelope;
    case 'TRANSMISSION_ISSUES':
      return alert;
    default:
      return '';
  }
};

const EmailRecipientsCard = ({ isLoading, recipients, type }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const renderRecipients = (rec) => {
    if (rec.length === 0) {
      return (
        <span className="text-secondary">There are no recipients yet</span>
      );
    }

    const recipientEmails = rec.map((recipient) => recipient.email);

    if (recipientEmails.length <= MAX_RECIPIENTS_DISPLAY) {
      return (
        <span className="text-secondary">{recipientEmails.join(' • ')}</span>
      );
    }

    const displayedRecipients = recipientEmails
      .slice(0, MAX_RECIPIENTS_DISPLAY)
      .join(' • ');
    const remainingCount = recipientEmails.length - MAX_RECIPIENTS_DISPLAY;

    const tooltipId = `TooltipExample-${type}`;

    return (
      <span className="text-secondary fs-6">
        {displayedRecipients} •
        <span id={tooltipId} className="text-secondary fs-6">
          +{remainingCount} more
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={tooltipId}
          toggle={toggleTooltip}
        >
          {recipientEmails.join(', ')}
        </Tooltip>
      </span>
    );
  };

  const title = getTitle(type);
  const description = getDescription(type);
  const envelopeImage = getEnvelopeImage(type);

  return (
    <StyledCard>
      <CardBody>
        <div>
          <div className="mb-3">
            <img src={envelopeImage} alt="email image" />
          </div>
          <CardTitle tag="span" className="h5 fs-4">
            <p className="mb-3" style={{ fontSize: '15px' }}>
              {title}
            </p>
          </CardTitle>
          <CardText
            className="text-secondary mb-3"
            style={{
              fontSize: '14px',
              overflow: 'hidden',
              height: '42px',
              textOverflow: 'ellipsis',
            }}
          >
            {description}
          </CardText>
          {isLoading ? (
            <p className="text-secondary fs-6">Loading...</p>
          ) : (
            renderRecipients(recipients)
          )}
        </div>
      </CardBody>
    </StyledCard>
  );
};

EmailRecipientsCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  recipients: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired, // Pass the recipient type as a prop for tooltip uniqueness
};

export default EmailRecipientsCard;
