import makeMultiValueFilter from './MultiValueFilter';
import makeSelectorFilter from './SelectorFilter';
import makeInputFilter from './InputFilter';
import makeDateInputFilter from './FilterWithDatePicker';
import makeDateRangeInputFilter from './FilterWithDateRange';
import makeMultiSelectorFilter from './MultiSelectorFilter';
import makeCheckboxFilter from './CheckBoxFilter';

export const SerialNumberFilter = makeMultiValueFilter({
  column: 'serialNumber',
  placeholder: 'Search by Serial No...',
});

export const SalesOrderNumberFilter = makeMultiValueFilter({
  column: 'salesOrderNumber',
  placeholder: 'Search by Sales Order No...',
});

export const UidFilter = makeMultiValueFilter({
  column: 'uid',
  placeholder: 'Search by UID...',
});

export const SvcpnFilter = makeSelectorFilter({
  column: 'svcpn',
  placeholder: 'SVC PN',
});

export const ProjectFilter = makeSelectorFilter({
  column: 'projectCode',
  placeholder: 'Project No',
});

export const StatusFilter = makeSelectorFilter({
  column: 'status',
  placeholder: 'Status',
});

export const ShippedToCustomerNameFiler = makeInputFilter({
  column: 'customerShipName',
  placeholder: 'Shipped to Customer Name',
});

export const SoldToCustomerNameFiler = makeInputFilter({
  column: 'customerSoldName',
  placeholder: 'Sold to Customer Name',
});

export const ReferenceFiler = makeInputFilter({
  column: 'reference',
  placeholder: 'Reference No',
});

export const GeographyFilter = makeMultiSelectorFilter({
  column: 'regionName',
  placeholder: 'Geography',
});

export const OrderStatusFilter = makeSelectorFilter({
  column: 'orderStatus',
  placeholder: 'Status',
});

export const OffSetFilter = makeInputFilter({
  column: 'offset',
  placeholder: 'Offset',
});

export const ShipCountryFilter = makeMultiSelectorFilter({
  column: 'salesOrderItemShippingCountry',
  placeholder: 'Ship Country',
});

export const MachineShipDateFilter = makeDateInputFilter({
  column: 'salesOrderItemShipAt',
  placeholder: 'Machine Ship Date',
});

export const ProductFilter = makeMultiSelectorFilter({
  column: 'product',
  placeholder: 'Product',
});

export const RangeOrderDateFilter = makeDateRangeInputFilter({
  column: 'orderedAt',
  placeholder: 'Order date',
});

export const OrderDateFilter = makeDateInputFilter({
  column: 'orderedAt',
  placeholder: 'Order date',
});

export const ProjectStatusFilter = makeSelectorFilter({
  column: 'deleted',
  placeholder: 'All Projects',
});

export const OutdatedAtFilter = makeSelectorFilter({
  column: 'outdatedAt',
  placeholder: 'Status',
});

export const ShowCanceledFilter = makeCheckboxFilter({
  column: 'showCanceled',
  placeholder: 'Show Canceled',
});
