import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../../components/Common/CloseIcon';
import AssignToProjectForm from './AssignToProjectForm';
import {
  toggleAssignAllModal,
  checkCanceledStatus,
  clearCanceledRecordsCount,
} from '../../../store/records';
import { clearRelatedRetirement } from '../../../store/projects';
import { useProjects } from '../../../hooks';
import { format } from '../../../helpers/formatter';
import CancelledRecordsModal from '../../shared/CancelledRecordsModal';

const Header = styled(ModalHeader)`
  padding: 16px 24px 0 24px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 18px 24px 0 24px;
  border-bottom: 0;

  .modal-title {
    width: 100%;
  }
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
`;

const TotalRow = styled(Row)`
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 400;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const ModalBodyStyled = styled(ModalBody)`
  padding: 16px 24px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const MutedCol = styled(Col)`
  color: rgba(111, 114, 118, 1);
  font-size: 14px;
  font-weight: 400;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: 37px;
  padding: 4px 20px;
  margin-left: 10px;
`;

const AssignToProject = ({ setIsOpen, isOpenAssignModal, params }) => {
  const dispatch = useDispatch();
  const { data } = useProjects();
  const [isCanceledModal, setIsCanceledModal] = useState(false);
  const { totalOffsetOfNew, withStatusNew, canceledRecordsCount } = useSelector(
    (state) => ({
      totalOffsetOfNew: state.records.records.totalOffsetOfNew,
      withStatusNew: state.records.records.withStatusNew,
      canceledRecordsCount: state.records.canceledRecordsCount,
    }),
  );

  const toggleModal = async () => {
    dispatch(clearRelatedRetirement());
    setIsOpen(!isOpenAssignModal);

    await dispatch(
      checkCanceledStatus({
        queryParams: params,
        type: 'assign',
      }),
    );

    return dispatch(toggleAssignAllModal());
  };

  const handleProceedAfterCancel = () => {
    setIsCanceledModal(false);
    dispatch(clearCanceledRecordsCount());
  };

  useEffect(() => {
    if (canceledRecordsCount > 0 && isOpenAssignModal) {
      setIsCanceledModal(true);
    }
  }, [canceledRecordsCount, isOpenAssignModal]);

  const closeCanceledModal = () => {
    setIsCanceledModal(false);
    setIsOpen(false);
    dispatch(clearCanceledRecordsCount());
  };

  return (
    <>
      <StyledButton
        color="primary"
        disabled={!withStatusNew || withStatusNew === 0}
        onClick={toggleModal}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Assign all "new" records</span>
      </StyledButton>

      <CancelledRecordsModal
        isOpen={isCanceledModal}
        toggleModal={closeCanceledModal}
        canceledRecordsCount={canceledRecordsCount}
        handleProceed={handleProceedAfterCancel}
      />

      <StyledModal toggle={toggleModal} isOpen={isOpenAssignModal}>
        <Header>
          <Row>
            <TitleCol>Assign to project</TitleCol>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </Header>
        <TotalsHeader>
          <TotalRow>
            <MutedCol xs={6}>Total affected records</MutedCol>
            <Col xs={6} style={{ fontWeight: '500', fontSize: '14px' }}>
              {format(withStatusNew)}
            </Col>
          </TotalRow>
          <TotalRow>
            <MutedCol xs={6}>Total remaining carbon</MutedCol>
            <Col xs={6} style={{ fontWeight: '500', fontSize: '14px' }}>
              {format(totalOffsetOfNew)} tonne
            </Col>
          </TotalRow>
        </TotalsHeader>
        <ModalBodyStyled>
          <AssignToProjectForm
            params={params}
            isOpenAssignModal={isOpenAssignModal}
            setIsOpen={setIsOpen}
            data={data}
          />
        </ModalBodyStyled>
      </StyledModal>
    </>
  );
};

export default AssignToProject;

AssignToProject.propTypes = {
  setIsOpen: PropTypes.func,
  params: PropTypes.object.isRequired,
  isOpenAssignModal: PropTypes.bool.isRequired,
};
