import { asyncAction } from '../common';
import {
  GET_BILLING_SUMMARY,
  EDIT_BILLING_SUMMARY,
  DELETE_BILLING_SUMMARY,
  CLEAR_EDIT_BILLINGS_ERROR,
  SENT_BILLING_SUMMARY,
  DOWNLOAD_BILLING_SUMMARY_BY_VBELP,
  DOWNLOAD_BILLING_SUMMARY_BY_SALES_ORDER,
} from './actionTypes';

export const getBillingSummary = asyncAction(GET_BILLING_SUMMARY);
export const editBillingSummary = asyncAction(EDIT_BILLING_SUMMARY);
export const sentBillingSummary = asyncAction(SENT_BILLING_SUMMARY);
export const downloadBillingSummaryByVBELP = asyncAction(
  DOWNLOAD_BILLING_SUMMARY_BY_VBELP,
);
export const downloadBillingSummaryBySalesOrder = asyncAction(
  DOWNLOAD_BILLING_SUMMARY_BY_SALES_ORDER,
);
export const deleteBillingSummary = asyncAction(DELETE_BILLING_SUMMARY);
export const clearBillingsError = asyncAction(CLEAR_EDIT_BILLINGS_ERROR);
