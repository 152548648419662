import { asyncActionType } from '../common';

export const GET_BILLING_SUMMARY = asyncActionType('GET_BILLING_SUMMARY');
export const EDIT_BILLING_SUMMARY = asyncActionType('EDIT_BILLING_SUMMARY');
export const SENT_BILLING_SUMMARY = asyncActionType('SENT_BILLING_SUMMARY');
export const DOWNLOAD_BILLING_SUMMARY_BY_VBELP = asyncActionType(
  'DOWNLOAD_BILLING_SUMMARY_BY_VBELP',
);
export const DOWNLOAD_BILLING_SUMMARY_BY_SALES_ORDER = asyncActionType(
  'DOWNLOAD_BILLING_SUMMARY_BY_SALES_ORDER',
);
export const DELETE_BILLING_SUMMARY = asyncActionType('DELETE_BILLING_SUMMARY');
export const CLEAR_EDIT_BILLINGS_ERROR = asyncActionType(
  'CLEAR_EDIT_BILLINGS_ERROR',
);
