import * as actions from './actions';

export const getBillingSummary = actions.getBillingSummary.run;
export const editBillingSummary = actions.editBillingSummary.run;
export const sentBillingSummary = actions.sentBillingSummary.run;
export const downloadBillingSummaryByVBELP =
  actions.downloadBillingSummaryByVBELP.run;
export const downloadBillingSummaryBySalesOrder =
  actions.downloadBillingSummaryBySalesOrder.run;
export const deleteBillingSummary = actions.deleteBillingSummary.run;
export const clearBillingsError = actions.clearBillingsError.run;
