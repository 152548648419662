import { takeEvery, call, put } from 'redux-saga/effects';
import {
  loginUser,
  sendSMSCode,
  verifySMSCode,
  registerOTPApp,
  verifyOTPApp,
  verifyOTPCode,
  logOutUser,
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  updateUserPassword,
  toggleUserModal,
  sendPasswordResetEmail,
  checkToken,
  updatePassword,
  storeRememberMe,
  resetSecondFactor,
} from './actions';
import {
  LOGIN_USER,
  SEND_SMS_CODE,
  VERIFY_SMS_CODE,
  REGISTER_OTP_APP,
  VERIFY_OTP_APP,
  VERIFY_OTP_CODE,
  LOGOUT_USER,
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  SEND_PASSWORD_RESET_EMAIL,
  CHECK_TOKEN,
  UPDATE_PASSWORD,
  RESET_SECOND_FACTOR,
} from './actionTypes';
import { users } from '../../api';
import Notification from '../../components/Common/Notification';

function* loginSaga({ payload: { params, history } }) {
  try {
    const response = yield call(users.loginUser, params);
    yield put(storeRememberMe.success({ rememberMe: params.rememberMe }));
    yield put(loginUser.success(response.data.data));
    history.push('/2fa-login');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 404:
      case 401:
        return yield put(loginUser.fail('Email or password is wrong!'));
      case 422:
        return yield put(loginUser.fail('Invalid request params')); // TODO: fix joi error processing
      default:
        return yield put(loginUser.fail('Something went wrong'));
    }
  }
}

function* sendSMSCodeSaga({ payload: { params } }) {
  try {
    const response = yield call(users.sendSMSCode, params);
    yield put(sendSMSCode.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* verifySMSCodeSaga({ payload: { params, history } }) {
  try {
    const response = yield call(users.verifySMSCode, params);
    yield put(verifySMSCode.success(response.data.data));
    Notification('success', 'Logged in successfully');
    history.push('/home');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 401:
        return yield put(verifySMSCode.fail('Incorrect code'));
      default:
        return yield put(verifySMSCode.fail('Something went wrong'));
    }
  }
}

function* registerOTPSaga({ payload: { params, history } }) {
  try {
    const response = yield call(users.registerOTPApp, params);
    yield put(registerOTPApp.success(response.data.data));
    history.push('/2fa-login/authenticator');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* verifyOTPAppSaga({ payload: { params, history } }) {
  try {
    const response = yield call(users.verifyOTPApp, params);
    yield put(verifyOTPApp.success(response.data.data));
    Notification('success', 'OTP app verified successfully!');
    Notification('info', 'Please wait for another code');
    history.push('/2fa-login/authenticator');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 401:
        return yield put(verifyOTPApp.fail('Incorrect code'));
      default:
        return yield put(verifyOTPApp.fail('Something went wrong'));
    }
  }
}

function* verifyOTPSaga({ payload: { params, history } }) {
  try {
    const response = yield call(users.verifyOTPCode, params);
    yield put(verifyOTPCode.success(response.data.data));
    Notification('success', 'Logged in successfully');
    history.push('/home');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 401:
        return yield put(verifyOTPCode.fail('Incorrect code'));
      default:
        return yield put(verifyOTPCode.fail('Something went wrong'));
    }
  }
}

function* logOutSaga({ payload: { history } }) {
  try {
    const response = yield call(users.logOutUser);
    history.push('/login');
    yield put(logOutUser.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* getUsersSaga({ payload: { params } }) {
  try {
    const response = yield call(users.getUsers, params);
    yield put(getUsers.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* createUserSaga({ payload: { email, password, role, phoneNumber } }) {
  try {
    const response = yield call(users.createUser, {
      email,
      password,
      role,
      phoneNumber,
    });
    const { user } = response.data.data;
    yield put(createUser.success({ user }));
    yield put(toggleUserModal.run({ modalType: '' }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 401:
        return yield put(createUser.fail('Email or password is wrong!'));
      case 409:
        return yield put(createUser.fail('Email has alredy been taken!'));
      case 422:
        return yield put(createUser.fail('Invalid request params')); // TODO: fix joi error processing
      default:
        return yield put(createUser.fail('Something went wrong'));
    }
  }
}

function* updateUserSaga({ payload: { email, role, id, phoneNumber } }) {
  try {
    const response = yield call(users.updateUser, {
      id,
      email,
      role,
      phoneNumber,
    });
    const { user } = response.data.data;
    yield put(updateUser.success({ user }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* sendResetPasswordEmailSaga({ payload: { email, history } }) {
  try {
    const response = yield call(users.sendResetEmail, { email });
    yield put(sendPasswordResetEmail.success(response.data.data));
    history.push({
      pathname: '/email-sent',
      state: { email },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 404:
      case 401:
        return history.push({
          pathname: '/email-sent',
          state: { email },
        });
      case 422:
        return yield put(sendPasswordResetEmail.fail('Invalid request params')); // TODO: fix joi error processing
      default:
        return yield put(sendPasswordResetEmail.fail('Something went wrong'));
    }
  }
}

function* checkPasswordResetTokenSaga({ payload: { email, token, history } }) {
  try {
    const response = yield call(users.checkPassResetToken, { email, token });
    yield put(checkToken.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    switch (error.response?.status) {
      case 422:
        yield put(checkToken.fail('The password reset link has expired!'));
        return history.push('/login');
      case 404:
      default:
        return yield put(checkToken.fail('Something went wrong'));
    }
  }
}

function* updateUserPasswordSaga({ payload: { params, id } }) {
  const { password } = params;
  try {
    const response = yield call(users.updateUserPassword, { id, password });
    yield put(updateUserPassword.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* updatePasswordSaga({ payload: { params, history } }) {
  const { password, email } = params;
  try {
    const response = yield call(users.updatePassword, { email, password });
    yield put(updatePassword.success(response));
    return history.push('/login');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* deleteUserSaga({ payload: { id, user } }) {
  try {
    yield call(users.deleteUser, { id, user });
    yield put(deleteUser.success({ id }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* resetSecondFactorSaga({ payload: { id } }) {
  try {
    yield call(users.resetSecondFactor, { id });
    yield put(resetSecondFactor.success({ id }));
    Notification('success', 'Second factor has been successfully reset!');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* usersSaga() {
  yield takeEvery(CREATE_USER.RUN, createUserSaga);
  yield takeEvery(DELETE_USER.RUN, deleteUserSaga);
  yield takeEvery(GET_USERS.RUN, getUsersSaga);
  yield takeEvery(LOGIN_USER.RUN, loginSaga);
  yield takeEvery(LOGOUT_USER.RUN, logOutSaga);
  yield takeEvery(SEND_SMS_CODE.RUN, sendSMSCodeSaga);
  yield takeEvery(VERIFY_SMS_CODE.RUN, verifySMSCodeSaga);
  yield takeEvery(REGISTER_OTP_APP.RUN, registerOTPSaga);
  yield takeEvery(VERIFY_OTP_APP.RUN, verifyOTPAppSaga);
  yield takeEvery(VERIFY_OTP_CODE.RUN, verifyOTPSaga);
  yield takeEvery(UPDATE_USER_DATA.RUN, updateUserSaga);
  yield takeEvery(UPDATE_USER_PASSWORD.RUN, updateUserPasswordSaga);
  yield takeEvery(UPDATE_PASSWORD.RUN, updatePasswordSaga);
  yield takeEvery(SEND_PASSWORD_RESET_EMAIL.RUN, sendResetPasswordEmailSaga);
  yield takeEvery(CHECK_TOKEN.RUN, checkPasswordResetTokenSaga);
  yield takeEvery(RESET_SECOND_FACTOR.RUN, resetSecondFactorSaga);
}
