import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './query';
import { getRecords, fetchFilters } from '../store/records';

export const useAdvancedSearch = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'customerShipName',
    sortingDirection: 'desc',
    showCanceled: true,
  },
) => {
  return {
    data: useSelector((state) => state.records.records.results),
    affectedRows: useSelector(
      (state) => state.records.records.numberOfAffectedRows,
    ),
    sortableColumns: useSelector(
      (state) => state.records.records.params.sortableColumns,
    ),
    totalOffsetOfNew: useSelector(
      (state) => state.records.records.totalOffsetOfNew,
    ),
    totalOffsetExceptInvalidAndDuplicate: useSelector(
      (state) => state.records.records.totalOffsetExceptInvalidAndDuplicate,
    ),
    totalOffsetRetired: useSelector(
      (state) => state.records.records.totalOffsetRetired,
    ),
    isCanceled: useSelector((state) => state.records.records.isCanceled),
    withStatusNew: useSelector((state) => state.records.records.withStatusNew),
    withStatusReady: useSelector(
      (state) => state.records.records.withStatusReady,
    ),
    withStatusDone: useSelector(
      (state) => state.records.records.withStatusDone,
    ),
    withStatusInProgress: useSelector(
      (state) => state.records.records.withStatusInProgress,
    ),
    withStatusInvalid: useSelector(
      (state) => state.records.records.withStatusInvalid,
    ),
    withStatusDuplicate: useSelector(
      (state) => state.records.records.withStatusDuplicate,
    ),
    withStatusBilled: useSelector(
      (state) => state.records.records.withStatusBilled,
    ),
    filteredCount: useSelector((state) => state.records.records.filteredCount),
    countAllExceptInvalidAndDuplicate: useSelector(
      (state) => state.records.records.countAllExceptInvalidAndDuplicate,
    ),
    count: useSelector((state) => state.records.records.count),
    processing: useSelector((state) => state.records.records.processing),
    isLoading: useSelector((state) => state.records.isLoading),
    initialParams,
  };
};

export const useFetchFilters = (
  initialParams = {
    page: 1,
    pageSize: 50,
    sortingColumn: 'customerShipName',
    sortingDirection: 'desc',
    showCanceled: true,
  },
) => {
  const [params, updateParams] = useQuery(initialParams);
  const dispatch = useDispatch();
  const [pageChanged, setPageChanged] = useState(false);
  const [sortChanged, setSortChanged] = useState(false);

  // to make sure that the records are fetched when the innital params are changed
  useEffect(() => {
    if (pageChanged || sortChanged) {
      dispatch(getRecords(params));
      setPageChanged(false);
      setSortChanged(false);
    }
  }, [dispatch, params, pageChanged]);

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  const loadRecords = () => {
    dispatch(getRecords(params));
  };

  const makeSetFilter = useCallback(
    (column, forceGetRecords = false, keepFalseValue = false) =>
      (value) => {
        const newParams = {
          ...params,
          page: 1,
          [column]: value,
        };
        const keysToClear = [];
        if (!value && !keepFalseValue) keysToClear.push(column);

        updateParams(newParams, keysToClear);

        if (forceGetRecords) {
          dispatch(getRecords(newParams));
        }
      },
    [params, updateParams, dispatch],
  );

  const availableFilters = useSelector((state) =>
    state.records.filters.availableFilters.reduce((acc, filter) => {
      acc[filter.column] = filter;
      return acc;
    }, {}),
  );

  const filters = Object.keys(params).reduce((acc, key) => {
    if (
      key !== 'page' &&
      key !== 'pageSize' &&
      key !== 'sortingColumn' &&
      key !== 'sortingDirection'
    ) {
      acc[key] = {
        column: key,
        value: params[key],
      };
    }
    return acc;
  }, {});

  return {
    availableFilters,
    filters,
    loadRecords,
    makeSetFilter,
    params,
    updateParams,
    setPageChanged,
    setSortChanged,
  };
};
