import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'reactstrap';
import AssignToProject from './AssignToProject';
import { withUser } from '../../context/UserContext';
import MultiProcessRecord from './ProcessRecord';

const RecordActionButtons = ({ params, isOpen, selectedRecords, user }) => {
  const firstRecord = selectedRecords[0];
  const isSameRecordStatus = selectedRecords.every(
    (record) => firstRecord.status === record.status,
  );

  return (
    <Row className="mt-3">
      <Form className="row row-cols-lg-auto">
        <Col className="mb-3" xs={12}>
          {(user && user.role === 'ADMIN') || user.role === 'SALES' ? (
            <Form className="row row-cols-lg-auto">
              {firstRecord.status === 'READY' && isSameRecordStatus && (
                <Col className="mb-3" xs={12}>
                  <MultiProcessRecord
                    selectedRecords={selectedRecords}
                    urlParams={params}
                  />
                </Col>
              )}
              {firstRecord.status === 'NEW' && isSameRecordStatus && (
                <Col className="mb-3" xs={12}>
                  <AssignToProject
                    params={params}
                    isOpenAssignModal={isOpen}
                    selectedRecords={selectedRecords}
                  />
                </Col>
              )}
            </Form>
          ) : null}
        </Col>
      </Form>
    </Row>
  );
};

RecordActionButtons.propTypes = {
  params: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  selectedRecords: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(RecordActionButtons);
