import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { NotFound } from '../errors';
import PageTitle from '../../components/Common/PageTitle';
import CreateUser from './CreateUser';
import UserMenu from './UserMenu';
import { useUsers } from '../../hooks';
import { withUser } from '../../context/UserContext';
import UserTable from './UserTable';

const CreateUserCol = styled(Col)`
  display: grid;
  place-content: flex-end;
`;

const columns = [
  {
    dataField: 'email',
    text: 'Email',
    headerStyle: () => {
      return { width: '27%' };
    },
  },
  {
    dataField: 'phoneNumber',
    text: 'Phone Number',
    headerStyle: () => {
      return { width: '27%' };
    },
  },
  {
    dataField: 'role',
    text: 'Role',
    headerStyle: () => {
      return { width: '27%' };
    },
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (cell, row) => <UserMenu row={row} />,
    headerStyle: () => {
      return { width: '21%' };
    },
  },
];

const Users = ({ user }) => {
  if (!user || user.role !== 'ADMIN') {
    return <NotFound />;
  }

  const {
    data,
    totalSize,
    isLoading,
    paginationParams,
    updatePaginationParams,
    fetchUsers,
  } = useUsers();

  const handlePageChange = (newPage) => {
    updatePaginationParams({ page: newPage });
  };

  useEffect(() => {
    fetchUsers();
  }, [paginationParams]);

  return (
    <div className="page-content">
      <PageTitle title="Users" />
      <Container fluid>
        <Row>
          <Col xs={10}>
            <h3>Users</h3>
          </Col>
          <CreateUserCol xs={2}>
            <CreateUser />
          </CreateUserCol>
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <UserTable
                  isLoading={isLoading}
                  data={data || []}
                  columns={columns}
                  page={paginationParams.page}
                  sizePerPage={paginationParams.pageSize}
                  totalSize={totalSize}
                  onPageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Users.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(Users);
