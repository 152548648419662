import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../../components/Common/CloseIcon';
import {
  bulkAction,
  checkCanceledStatus,
  clearCanceledRecordsCount,
} from '../../../store/records';
import { useToggle } from '../../../hooks';
import CancelledRecordsModal from '../../shared/CancelledRecordsModal';

const BorderedHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const StyledModal = styled(Modal)`
  width: 430px;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const BottomRow = styled(Row)`
  margin-top: 15px;
`;

const ModalButton = styled(Button)`
  width: 100px;
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: 37px;
  padding: 4px 20px;
  margin-left: 10px;
`;

const MultiProcessRecord = ({ params }) => {
  const [isOpen, toggleModal] = useToggle();
  const [isCanceledModal, setIsCanceledModal] = useState(false);
  const dispatch = useDispatch();

  const {
    withStatusReady,
    hasUnfinishedTask,
    processing,
    canceledRecordsCount,
  } = useSelector((state) => ({
    withStatusReady: state.records.records.withStatusReady,
    hasUnfinishedTask: state.records.records.hasUnfinishedTask,
    processing: state.records.records.processing,
    canceledRecordsCount: state.records.canceledRecordsCount,
  }));

  const toggleModalTmp = async () => {
    await dispatch(
      checkCanceledStatus({
        queryParams: params,
        type: 'process',
      }),
    );
    toggleModal();
  };

  const handleProcessRecord = () => {
    dispatch(
      bulkAction({
        queryParams: params,
        action: 'process',
        numberOfRecords: withStatusReady,
      }),
    );
    toggleModal();
  };

  const handleProceedAfterCancel = () => {
    setIsCanceledModal(false);
    dispatch(clearCanceledRecordsCount());
  };

  useEffect(() => {
    if (canceledRecordsCount > 0 && isOpen) {
      setIsCanceledModal(true);
    }
  }, [canceledRecordsCount, isOpen]);

  const closeCanceledModal = () => {
    setIsCanceledModal(false);
    toggleModal();
  };

  return (
    <>
      <span
        title={
          hasUnfinishedTask || processing ? 'Processing is in progress' : ''
        }
      >
        <StyledButton
          color="primary"
          disabled={
            !withStatusReady || withStatusReady === 0 || hasUnfinishedTask
          }
          onClick={toggleModalTmp}
          className="mt-on-tablet"
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <span>Process all "ready" records</span>
        </StyledButton>
      </span>

      <CancelledRecordsModal
        isOpen={isCanceledModal}
        toggleModal={closeCanceledModal}
        canceledRecordsCount={canceledRecordsCount}
        handleProceed={handleProceedAfterCancel}
      />

      <StyledModal toggle={toggleModal} isOpen={isOpen}>
        <BorderedHeader>
          <Row>
            <Col>
              <h4>Process record(s)</h4>
            </Col>
            <CloseButton color="link" onClick={toggleModal}>
              <CloseIcon />
            </CloseButton>
          </Row>
        </BorderedHeader>
        <ModalBody>
          <Row>
            <Col>Are you sure you want to process the record(s)?</Col>
          </Row>
          <BottomRow>
            <Col>
              <ModalButton onClick={toggleModal} color="secondary">
                Cancel
              </ModalButton>
            </Col>
            <Col className="text-end">
              <ModalButton onClick={handleProcessRecord} color="primary">
                Ok
              </ModalButton>
            </Col>
          </BottomRow>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default MultiProcessRecord;

MultiProcessRecord.propTypes = {
  params: PropTypes.object.isRequired,
};
